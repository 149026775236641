import(/* webpackMode: "eager", webpackExports: ["PrismicNextImage"] */ "/app/node_modules/.pnpm/@prismicio+next@1.5.0_@prismicio+client@7.3.1_next@14.2.13_react@18.2.0/node_modules/@prismicio/next/dist/PrismicNextImage.js");
;
import(/* webpackMode: "eager", webpackExports: ["PrismicNextLink"] */ "/app/node_modules/.pnpm/@prismicio+next@1.5.0_@prismicio+client@7.3.1_next@14.2.13_react@18.2.0/node_modules/@prismicio/next/dist/PrismicNextLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["PrismicPreviewClient"] */ "/app/node_modules/.pnpm/@prismicio+next@1.5.0_@prismicio+client@7.3.1_next@14.2.13_react@18.2.0/node_modules/@prismicio/next/dist/PrismicPreviewClient.js");
;
import(/* webpackMode: "eager", webpackExports: ["PrismicToolbar"] */ "/app/node_modules/.pnpm/@prismicio+react@2.7.3_@prismicio+client@7.3.1_react@18.2.0/node_modules/@prismicio/react/dist/PrismicToolbar.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next-axiom@1.1.1_next@14.2.13_react@18.2.0/node_modules/next-axiom/dist/webVitals/components.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.2.13_react-dom@18.2.0_react@18.2.0/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.2.13_react-dom@18.2.0_react@18.2.0/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.2.13_react-dom@18.2.0_react@18.2.0/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/app/public/doctor-placeholder.webp");
;
import(/* webpackMode: "eager" */ "/app/public/home-banner.webp");
;
import(/* webpackMode: "eager" */ "/app/public/sections/hospitals/bhayli.webp");
;
import(/* webpackMode: "eager" */ "/app/public/sections/hospitals/gandhidham.webp");
;
import(/* webpackMode: "eager" */ "/app/public/sections/hospitals/gurukul.webp");
;
import(/* webpackMode: "eager" */ "/app/public/sections/hospitals/racecourse.webp");
;
import(/* webpackMode: "eager" */ "/app/public/sections/hospitals/rajkot.webp");
;
import(/* webpackMode: "eager" */ "/app/public/sections/hospitals/sindhubhavan.webp");
;
import(/* webpackMode: "eager" */ "/app/src/assets/icons/sprite.svg");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/search-bar.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/components/ui/carousel.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["HeroForm"] */ "/app/src/forms/hero-form.tsx");
